.welcome-panel {
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0.01rem;
}

.welcome-ui {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.welcome-ui li {
  margin-right: 20px;
}

.login-user {
  display: block;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

.logout-user {
  border: none;
  background: none;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 0.02rem;
  line-height: 1.1;
  cursor: pointer;
}
