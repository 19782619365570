.component-stat-form {
  flex-basis: 35%;
  background-color: #fff;
  height: 32vh;
  border-radius: 20px;
  padding: 20px;
}

.component-stats-graph {
  flex-basis: 60%;
  height: 32vh;
}
