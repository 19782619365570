.report-search-form {
  flex-basis: 10%;
  background-color: var(--beta-color);
  border-radius: 20px;
  padding: 15px 0 0 15px;
  margin: 0 15px;
}

.report-expense-chart {
  display: flex;
  justify-content: space-between;
  flex-basis: 47%;
  padding: 25px 0;
  margin: 0 15px;
}

.report-data-panel {
  display: flex;
  justify-content: space-between;
  flex-basis: 35%;
  margin: 0 15px;
}

.monthlyreport-form h3 {
  margin: 0 0 25px 0;
  font-size: 1.1rem;
  color: var(--gamma-color);
}

.monthlyreport-form ul {
  width: 80%;
  display: flex;
  gap: 50px;
}

.monthlyreport-form label {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 5px;
  margin-right: 10px;
  color: var(--gamma-color);
}

.monthlyreport-form li {
  list-style-type: none;
}

.monthlyreport-form-button {
  padding: 10px 10px;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  align-self: center;
  background-color: var(--gamma-color);
  color: #fff;
  cursor: pointer;
}
