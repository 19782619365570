@media (max-width: 1980px) {
    .report-expense-chart {
        padding: 1em 0;
        margin: 0 1em;
    }

    .p-link {
        font-size: 0.85rem;
    }


}