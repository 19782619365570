.transact-container {
  display: grid;
  grid-template-rows: 8% 14% 28% 50%;
  height: 100%;
}

.transact-form-panel {
  align-self: center;
}

.search-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
}

.search-box input {
  width: 100%;
  padding: 6px 10px 6px 40px; /* Add left padding for the icon */
  font-size: 16px;
}

.search-icon {
  position: absolute;
  margin-left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}
