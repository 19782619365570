@media (max-width: 1980px) {
    html {
        font-size: 16px;
    }
    
    .login-form {
        padding: 2.5em 2em;
    }

    .login-form h3 {
        font-size: 2em;
        line-height: 1em;
        margin: 0.5em;
    }

    .login-form label {
        margin-top: 1.5em;
        font-size: 1em;
    }

    .login-input {
        font-size: 1rem;
        height: 3em;
        padding-left: 1em;
        margin-top: 0.5em;
    }

    .login-button {
        margin-top: 2.5em;
        padding: 1em 0;
        font-size: 1em;
    }

    .login-background .shape {
        height: 10em;
        width: 10em;
    }

    .shape:first-child {
        left: -5em;
        top: -5em;
    }

    .shape:last-child {
        right: -5em;
        bottom: -4em;
    }

    .login-background {
        height: 50%;
    }
}


@media (min-width: 1200px) and (max-width: 1980px) {
    /* Styles for screens between 1200px and 1980px */
}