.smartcard-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.smartcard-frame {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
  width: 22%;
}

.sc-top-section {
  display: grid;
  grid-template-columns: auto 1fr auto; /* First two columns for header & datapoint, third column for icon */
  grid-template-rows: auto auto; /* Two rows: one for the header, one for the datapoint */
  gap: 2px;
}

.sc-header {
  color: #67748e;
  grid-column: 1 / span 2; /* First two columns for the header */
  grid-row: 1; /* Occupies the first row */
  padding: 5px;
  font-size: 14px;
  letter-spacing: 0.01rem;
}

.sc-datapoint {
  grid-column: 1 / span 2; /* First two columns for the datapoint */
  grid-row: 2; /* Occupies the second row */
  color: #344767;
  font-weight: 700;
  font-size: 1.25rem;
  padding-left: 5px;
}

.sc-icon {
  grid-column: 3; /* Third column for the icon */
  grid-row: 1 / span 2; /* Span across both rows */

  justify-self: end; /* Align the icon to the right */
}

.icon-style {
  display: flex;
  font-size: 1.25rem;
  height: 3.5rem;
  width: 3.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
}

.sc-bottom-section {
  margin-top: 20px;
  padding: 10px;
  font-size: 1rem;
  letter-spacing: 0.04rem;
  font-weight: 700;
}

.expense-color {
  background: linear-gradient(310deg, #f5365c, #f56036);
  color: #fff;
}

.income-color {
  background: linear-gradient(310deg, #2dce89, #2dcecc);
  color: #fff;
}

.savings-color {
  background: linear-gradient(310deg, #1171ef, #11cdef);
  color: #fff;
}

.zakat-color {
  background: linear-gradient(310deg, #fb6340, #fbb140);
  color: #fff;
}
