/* InputField.css */
.input-container {
  display: grid;
  justify-self: stretch;
}

.input-field {
  padding-left: 20px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Glow effect on focus */
.input-field:focus {
  border: 1px solid #a5c0de;
  /* box-shadow: 0 0 8px rgba(74, 144, 226, 0.6); */
  outline: none; /* Remove default outline */
}

.select-container {
  margin-bottom: 1rem;
}

.select-field {
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;

  padding-right: 30px; /* Add extra padding to make room for the arrow */
  appearance: none; /* Remove default arrow */
  background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path fill="white" d="M7 10l5 5 5-5z"/></svg>')
    no-repeat right 15px center;
  background-color: #21a372;
  background-size: 30px; /* Size of the arrow */
}
