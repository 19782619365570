.core-layout {
  height: 100vh;
}

.sidebar-content {
  display: flex;
  height: 100%;
  width: 10%;
  min-width: 300px;
}

.main-content {
  height: 100%;
  width: 90%;
  margin-right: 15px;
}
