.transact-form {
  color: #ffffffc8;
  background-color: var(--gamma-color);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  /* border: 2px solid rgba(255, 255, 255, 0.1); */
  box-shadow: 0 0 1px rgba(8, 7, 16, 0.6);
  padding: 10px 35px 20px 10px;
  display: flex;
  flex-direction: column;
}

.transact-form-header {
  display: flex;
}

.transact-form h3 {
  margin: 0 0 25px 0;
  font-size: 1.1rem;
}

.transact-form label {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.transact-form ul {
  display: flex;
  justify-content: space-around;
}

.transact-form-button {
  margin-top: 15px;
  padding: 10px 0;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 20px;
  width: 10%;
  min-width: 150px;
  align-self: center;
  background-color: var(--alpha-color);
  color: #fff;
  cursor: pointer;
}
