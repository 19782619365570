.dual-background {
  height: 100vh;
  background: linear-gradient(
    to bottom,
    var(--alpha-color) 22%,
    var(--beta-color) 22%
      /* The 20% values in linear-gradient specify the exact point where the color transition happens.*/
  );
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
