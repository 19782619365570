.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: var(--gamma-color);
  border-right: 2px solid lavender;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: var(--alpha-color);
  border-color: var(--alpha-color);
}

.p-dropdown {
  min-width: 200px;
}

.p-dropdown:hover,
.p-dropdown:focus,
.p-dropdown:active,
.p-dropdown:focus-within {
  box-shadow: none;
}

/* Ensure the label and trigger cover the full area */
.p-dropdown-trigger {
  background-color: transparent;
}

.p-dropdown-label {
  background-color: transparent;
}

.p-inputtext {
  background-color: var(--beta-color);
}
/*
.p-tabview .p-tabview-nav li {
  flex-grow: 1;
}

.p-tabview-panel {
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

*/
