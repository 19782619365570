.date-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker__input-container input {
  font-size: 16px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
