body {
  background-color: #080710;
  background-image: radial-gradient(
    circle farthest-corner at 16.5% 28.1%,
    rgba(15, 27, 49, 1) 0%,
    rgba(0, 112, 218, 1) 90%
  );
}

.login-background {
  width: 30%;
  height: 40%;
  position: absolute;
}

.login-background .shape {
  height: 180px;
  width: 180px;
  position: absolute;
  border-radius: 50%;
}

.shape:first-child {
  /* background: linear-gradient(#1845ad, #23a2f6); */
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
  left: -90px;
  top: -90px;
}
.shape:last-child {
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -90px;
  bottom: -110px;
}

.login-form {
  display: grid;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 40px 35px;
  /*
    height: 440px;
  width: 400px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  */
}

.login-form * {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}
.login-form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

.login-form label {
  display: block;
  margin-top: 30px;
  font-size: 17px;
  font-weight: 500;
}

.input-field {
  display: grid;
  justify-self: stretch;
}

.login-input {
  display: block;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  padding-left: 20px;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 300;
  color: paleturquoise;
}
::placeholder {
  color: #e5e5e5;
}

.login-button {
  margin-top: 50px;
  width: 100%;
  background-color: #ffffff;
  color: #080710;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}

#login-image {
  background-image: url("../../assets/images/img-login.jpg");
  border-radius: 10px;
  background-size: cover; /* Adjust the background size (cover, contain, etc.) */
  background-position: center; /* Adjust the background position */
  background-repeat: no-repeat;
}
