.sidenav {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 15px;
  background-color: var(--gamma-color);
  border-radius: 20px;
}

h2 {
  line-height: 2.5;
  font-weight: 400;
  color: #fff;
  align-self: center;
  border-bottom: 1px solid gray;
}

.sidenav-ul {
  list-style: none;
  padding: 0;
  margin: 6px;
}

.sidenav-li {
  /*
  margin: 20px 20px;
  padding: 10px; */
  font-size: 0.9rem;
  letter-spacing: 0.03em;
  box-sizing: border-box;
  color: #fff;
}

.sidenav-item {
  text-decoration: none;
}

.submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.submenu.expanded {
  max-height: 150px; /* Adjust based on your expected maximum height */
}

.submenu-item {
  text-decoration: none;
}

.submenu-icon {
  margin-left: auto;
}

.theme-switcher {
  align-self: center;
  margin-top: auto;
  margin-bottom: 15px;
}

.calendar-color {
  color: #fb6340;
}

.credit-color {
  color: #2dce89;
}

.app-color {
  color: #11cdef;
}

.config-color {
  color: #f5365c;
}

.transact-color {
  color: #ffa726;
}

.add-color {
  color: rgb(87, 207, 87);
}

.update-color {
  color: rgb(255, 213, 0);
}

.pink-color {
  color: #f3a4b5;
}
