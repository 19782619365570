@media (max-width: 1980px) {
    .p-inputtext-sm .p-inputtext {
        font-size: 0.75rem;
    }

    .p-inputtext {
        font-size: 0.85rem;
    }

    .p-component {
        font-size: 0.75rem;
    }

    .transact-form {
        padding: 0.6em 2em 1.25em 0.6em;
    }

    .transact-form ul {
        padding-inline-start: 1em;
    }

    .p-dropdown {
        min-width: 6em !important;
    }

    .p-dropdown .p-dropdown-trigger {
        width: 2rem;
    }

    .p-paginator .p-dropdown {
        height: 2.5rem;
    }

    .transact-form h3 {
        font-size: 1rem;
        margin: 0 0 0.5em 0;
    }

    .transact-form label {
        font-size: 0.8rem;
    }

    .transact-form .p-inputtext {
        width: 14em;
    }

    .transact-form-button {
        margin-top: 0;
        font-size: 0.8rem;
    }

    .transact-container {
        grid-template-rows: 8% 14% 32% 46%;
    }
}