@media (max-width: 1980px) {
    .sidenav {
        margin: 0.8em;
    }

    .sidenav h2 {
        font-size: 1.2rem;
    }

    .sidenav-li {
        font-size: 0.80rem;
    }

    .sidebar-content {
        width: 15%;
        min-width: 250px;
    }

    .menu-item {
        padding: 1em;
        margin-top: 0.5em;
    }

    .theme-switcher {
        margin-bottom: 1em;
    }

    .p-button.p-button-icon-only {
        width: 2.8rem;
    }

    .main-content {
        width: 85%;
    }

    .welcome-panel {
        font-size: 0.9rem;
    }

    .logout-user {
        font-size: 0.9rem;
    }

    .smartcard-frame {
        border-radius: 1.25em;
        padding: 0.5em;
    }

    .sc-header {
        font-size: 0.75rem;
    }

    .sc-datapoint {
        font-size: 1rem;
    }

    .sc-bottom-section {
        margin-top: 1em;
        padding: 0.5em;
        font-size: 0.9rem;
    }

    .graphs-panel {
        margin: 0.8em;
    }

    .monthly-report-chart {
        width: 48%;
        background-color: #fff;
        border-radius: 1em;
    }

    .p-datatable-wrapper {
        max-height: 30vh !important;
    }

    .statements-panel .p-datatable-wrapper {
        max-height: 50vh !important;
    }

    .p-datatable-header {
        font-size: 0.8rem;
    }

    .p-datatable-table {
        min-width: 30rem !important;
    }

    .p-column-header-content {
        font-size: 0.85rem;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        font-size: 0.75rem;
    }
}