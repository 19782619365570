:root {
  --beta-color: #eff2f5;
  --gamma-color: #172b4d;
  --epsilon-color: aliceblue;
  --theta-color: #f1f2f6;

  --gradient-start-color: #0b8793;
  --gradient-end-color: #360033;
  --chart-border-color: #ff6b6b;
}

[data-theme="light"] {
  --alpha-color: #11cdef;
  --beta-color: #eff2f5;
  --gamma-color: #172b4d;
  --delta-color: #486481;
  --sidebar-active-color: #fff;
  --chart-border-color: #42a5f5;
}

[data-theme="dark"] {
  --alpha-color: #6a92a8;
  --beta-color: #eff2f5;
  --gamma-color: #7e4893;
  --delta-color: #f6c2ff;
  --sidebar-active-color: #000;
  --chart-border-color: #f2bdf4;
}

[data-theme="night"] {
  --alpha-color: #2c3e50;
  --beta-color: #ecf0f1;
  --gamma-color: #0a7167;
  --delta-color: #2bf2ac;
  --sidebar-active-color: #000;
  --chart-border-color: #2bf2ac;
}

[data-theme="vivid"] {
  --alpha-color: #b71717;
  --beta-color: #ecf0f1;
  --gamma-color: #097b8b;
  --delta-color: #abedee;
  --sidebar-active-color: #000;
  --chart-border-color: #84f3f5;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  /* font-family: "Open Sans", "Helvetica", "Arial", sans-serif; */
  font-family: var(--font-family);
  font-size: 1rem;
}

li {
  list-style-type: none;
}

.loader-wrapper {
  position: absolute;
  vertical-align: center;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
