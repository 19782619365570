.menu-item {
  display: flex;
  padding: 15px 20px;
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 6px;
}

.menu-item i {
  margin-right: 10px;
  align-content: center;
}

.menu-item.active {
  background-color: var(--delta-color);
  color: var(--sidebar-active-color);
}

.menu-item:hover {
  background-color: var(--delta-color);
  color: var(--sidebar-active-color);
}
